<template>
  <div class="bottom" style="padding-top: 14px">
    <span
      ><small
        ><strong>Mode: </strong> {{ MODES[tokenData.mode].name }}</small
      ></span
    >&nbsp;&nbsp;
    <span
      ><small
        ><strong>Style: </strong>
        {{ MODES[tokenData.mode].styles[tokenData.style].name }}</small
      ></span
    >
  </div>
</template>
<script>
  import { MODES } from '@/constants/toolPanelConstants'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: ['tokenData'],
    setup() {
      return {
        MODES,
      }
    },
  })
</script>
