<template>
  <keep-alive>
    <div>
      <div class="container">
        <div class="mobile">
          <div class="mobile-filters">
            <h4 v-if="projectSlug === 'norte'" class="title">Filter</h4>
            <div v-if="projectSlug === 'norte'">
              <div class="sorting-selector" style="margin: 15% 0% 5% 0%">
                <div class="label">Sort</div>
                <el-select
                  v-model="sortingSelected"
                  @change="handleSortingChange"
                  :disabled="disableFilters"
                  value-key="key"
                >
                  <el-option
                    v-for="(value, key) in SORTING_OPTIONS"
                    :key="key"
                    :label="value.name"
                    :value="key"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div v-if="projectSlug === 'norte'">
              <NorteMobileFilters @filterTokens="applyFilters" />
            </div>
            <div v-if="projectSlug === 'palabras'">
              <PalabrasMobileFilters @filterTokens="applyFilters" />
            </div>
            <div v-if="projectSlug === 'escaleras'">
              <EscalerasMobileFilters @filterTokens="applyFilters" />
            </div>
          </div>
        </div>
        <div class="desktop">
          <el-aside :width="sidebarWidth">
            <el-menu
              style="position: fixed; height: 100vh"
              height="100%"
              default-active="2"
              class="el-menu-vertical-demo"
              :collapse="isCollapse"
              @open="handleOpen"
              @close="handleClose"
            >
              <div class="menu-btns" v-if="!projectNotFound">
                <el-button
                  style="color: black; font-size: 0.7em"
                  v-if="!isCollapse"
                  type="text"
                  @click="handleClose"
                >
                  <el-icon>
                    <expand />
                  </el-icon>
                  <span style="margin: 0px 30px"><strong>Filter</strong></span
                  >&nbsp;&nbsp;&nbsp;&nbsp;
                  <el-icon>
                    <arrow-left />
                  </el-icon>
                </el-button>
                <el-menu-item v-if="isCollapse" index="2" @click="handleOpen">
                  <el-icon>
                    <arrow-right />
                  </el-icon>
                </el-menu-item>
                <el-scrollbar max-height="70vh">
                  <div v-if="!isCollapse" class="menu-controls">
                    <div class="sorting-selector" style="margin: 15% 0%">
                      <div class="s1-filter-label">Sort</div>
                      <el-select
                        v-model="sortingSelected"
                        @change="handleSortingChange"
                        value-key="key"
                        :disabled="disableFilters"
                      >
                        <el-option
                          v-for="(value, key) in SORTING_OPTIONS"
                          :key="key"
                          :label="value.name"
                          :value="key"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div v-if="projectSlug === 'norte'">
                      <NorteDesktopFilters @filterTokens="applyFilters" />
                    </div>
                    <div v-if="projectSlug === 'palabras'">
                      <PalabrasDesktopFilters @filterTokens="applyFilters" />
                    </div>
                    <div v-if="projectSlug === 'escaleras'">
                      <EscalerasDesktopFilters @filterTokens="applyFilters" />
                    </div>
                  </div>
                </el-scrollbar>
              </div>
            </el-menu>
          </el-aside>
        </div>
        <div class="s1-gallery-main">
          <div v-if="!projectNotFound" v-infinite-scroll="loadMore">
            <el-row>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <router-link
                  class="el-link el-link--default"
                  :to="`/${projectSlug}`"
                  style="margin: 20px 0px"
                >
                  <el-button type="primary" size="default">
                    Like what you see? Create your own
                  </el-button>
                </router-link>
                <hr style="border-color: #8080801a" />
              </el-col>
              <el-col v-if="hasNoResults && !loading">
                <el-result
                  icon="warning"
                  title="There are no tokens minted for this combination yet."
                >
                </el-result>
              </el-col>
              <el-col
                class="grow"
                v-for="tokenData in tokensData"
                :key="tokenData.tokenID"
                :xs="24"
                :sm="12"
                :md="12"
                :lg="8"
                :xl="6"
              >
                <router-link
                  class="el-link el-link--default"
                  :to="`/tokens/${tokenData.projectSlug}/${tokenData.tokenID}`"
                  style="width: 100% !important"
                >
                  <el-card
                    style="margin: 5%; width: 100% !important"
                    :body-style="{ padding: '10px' }"
                  >
                    <el-image
                      :src="imageFileName(tokenData.image)"
                      class="image"
                      fit="contain"
                      style="width: 100%; height: auto; aspect-ratio: 1"
                    >
                      <template #error>
                        <div
                          style="
                            width: 100%;
                            height: 0;
                            padding-top: 40%;
                            padding-bottom: 60%;
                            font-size: 20px;
                          "
                        >
                          <el-icon :size="50" style="width: 3em; height: 3em">
                            <full-screen style="width: 5em; height: 5em" />
                          </el-icon>
                          <br />Loading...
                        </div>
                      </template>
                    </el-image>
                    <div style="padding: 14px">
                      <span>{{ (tokenData || {}).name }}</span>
                      <div v-if="projectSlug === 'norte'">
                        <NorteTokenCard :tokenData="tokenData" />
                      </div>
                    </div>
                  </el-card>
                </router-link>
              </el-col>
            </el-row>
            <div class="infinitescrolldiv"></div>
          </div>
          <div v-else>
            <el-result
              icon="warning"
              title="404"
              sub-title="We're sorry, we couldn't find that anywhere in the Studio."
            >
              <template #extra>
                <router-link class="el-link el-link--default" :to="'/'">
                  <el-button type="primary" size="default">Back</el-button>
                </router-link>
              </template>
            </el-result>
          </div>
          <div class="loading" v-loading="loading"></div>
        </div>
      </div>
    </div>
  </keep-alive>
</template>
<script>
  import { useRoute } from 'vue-router'

  import { getProjectTokensPaginated } from '@/services/TokenService'

  import { onBeforeMount, computed, ref, defineComponent } from 'vue'
  import { useStore } from 'vuex'

  import {
    ArrowLeft,
    ArrowRight,
    Expand,
    FullScreen,
  } from '@element-plus/icons'

  import NorteDesktopFilters from '@/components/projects/norte/norte-gallery/desktop-filters'
  import PalabrasDesktopFilters from '@/components/projects/palabras/palabras-gallery/desktop-filters'
  import EscalerasDesktopFilters from '@/components/projects/escaleras/escaleras-gallery/desktop-filters'
  import NorteMobileFilters from '@/components/projects/norte/norte-gallery/mobile-filters'
  import NorteTokenCard from '@/components/projects/norte/norte-gallery/token-card'
  import PalabrasMobileFilters from '@/components/projects/palabras/palabras-gallery/mobile-filters'
  import EscalerasMobileFilters from '@/components/projects/escaleras/escaleras-gallery/mobile-filters'

  // Number of items per page
  const PAGINATION_OFFSET = 12

  export default defineComponent({
    name: 'Gallery',
    components: {
      ArrowLeft,
      ArrowRight,
      Expand,
      FullScreen,
      EscalerasDesktopFilters,
      EscalerasMobileFilters,
      PalabrasDesktopFilters,
      PalabrasMobileFilters,
      NorteDesktopFilters,
      NorteMobileFilters,
      NorteTokenCard,
    },
    setup() {
      const route = useRoute()
      const projectSlug = route.params.project_slug

      const store = useStore()
      const {
        dispatch,
        state: { gallery },
      } = store

      const isCollapse = ref(false)
      const sidebarWidth = ref('200px')
      const loading = ref(false)
      const projectNotFound = ref(false)
      const noMoreResults = ref(false)

      const sortingSelected = computed(() => gallery.sortingSelected)
      const paginationCursor = computed(() => gallery.paginationCursor)
      const tokensData = computed(() => gallery.tokensData)
      const tokenAttrs = computed(() => gallery.tokenAttrs)

      const disableFilters = computed(() => store.state.gallery.tokensData.length <= 0)

      const hasNoResults = ref(!!tokensData.value.length)

      const SORTING_OPTIONS = {
        1: {
          name: 'Oldest',
          field: 'tokenID',
          direction: 'asc',
        },
        2: {
          name: 'Latest',
          field: 'tokenID',
          direction: 'desc',
        },
      }

      const setStateFromQuery = (queryObject) => {
        if (queryObject.sorting) {
          dispatch('setSortingSelected', queryObject.sorting)
        }
      }

      const setQueryFromState = () => {
        const searchParams = new URLSearchParams()

        if (sortingSelected.value) {
          searchParams.set('sorting', sortingSelected.value)
        }

        if (tokenAttrs.value) {
          let sortedTokenAttrs = tokenAttrs.value?.sort((a, b) =>
            a.queryIndex > b.queryIndex ? 1 : -1
          )
          sortedTokenAttrs.forEach((attr) => {
            searchParams.set(attr.name, attr.value)
          })
        }
        history.replaceState({}, '', '?' + searchParams.toString())
      }

      const applyFilters = async () => {
        setQueryFromState()
        dispatch('resetTokensData')
        hasNoResults.value = true
        noMoreResults.value = false

        await loadTokens(true)
      }

      const handleOpen = () => {
        isCollapse.value = false
        sidebarWidth.value = '200px'
      }

      const handleClose = () => {
        isCollapse.value = true
        setTimeout(function () {
          sidebarWidth.value = '70px'
        }, 500)
      }

      const handleSortingChange = (sortingValue) => {
        dispatch('setSortingSelected', sortingValue)

        applyFilters()
      }

      const loadMore = async () => {
        if (!loading.value && !noMoreResults.value) {
          await loadTokens()
        }
      }

      const loadTokens = async (isInitial = false) => {
        loading.value = true

        try {
          const tokens = await getProjectTokensPaginated(
            projectSlug,
            isInitial ? 0 : paginationCursor.value,
            PAGINATION_OFFSET,
            SORTING_OPTIONS[sortingSelected.value].field,
            SORTING_OPTIONS[sortingSelected.value].direction,
            tokenAttrs.value,
            projectSlug == 'palabras'
          )

          if (tokens.length) {
            hasNoResults.value = false

            const data = tokens.map((token) => ({
              tokenID: token.tokenID,
              projectSlug: token.projectSlug,
              name: token.name || '1',
              image: token.image || '1',
              mode: token.mode || '1',
              style: token.style || '1',
              round: token.round || '1',
              word: token.word || '1',
              type: token.type || '1',
              font: token.font || '1',
              density: token.density || '1',
              palette: token.palette || '1',
            }))

            if (isInitial) {
              dispatch('setTokenData', data)
            } else {
              dispatch('pushTokenData', data)
            }

            dispatch('setPaginationCursor', data[data.length - 1].tokenID)
          } else {
            noMoreResults.value = true
          }
        } catch (e) {
          console.error(e)
        }

        loading.value = false
      }

      const imageFileName = (fileName) => {
        if (projectSlug == 'frases' && !fileName.includes('thumbnail')) {
          fileName = fileName.replace(/(\.[\w\d_-]+)$/i, '-thumbnail$1')
        }
        return fileName
      }

      onBeforeMount(async () => {
        const queryObject = route.query
        if (Object.keys(queryObject).length <= 1) {
          setQueryFromState()
        } else {
          setStateFromQuery(queryObject)
        }

        await loadTokens(true)
      })
      return {
        sidebarWidth,
        projectSlug,
        isCollapse,
        loading,
        projectNotFound,
        hasNoResults,
        tokensData,
        SORTING_OPTIONS,
        sortingSelected,
        disableFilters,
        applyFilters,
        loadTokens,
        handleOpen,
        handleClose,
        handleSortingChange,
        loadMore,
        imageFileName,
      }
    },
  })
</script>
<style scoped>
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }

  .container {
    display: flex;
  }

  .desktop {
    display: flex;
    float: left;
  }

  .mobile {
    display: none;
  }

  .container .el-main {
    float: right;
  }

  .s1-filter-label {
    font-weight: bolder;
    text-align: left;
    font-size: 0.8em;
    margin: 5%;
  }
  .s1-gallery-main {
    overflow: initial !important;
    width: 100%;
  }
  .loading {
    height: 40px;
    margin: 40px;
  }
  .grow {
    transition: all 0.2s ease-in-out !important;
  }
  .grow:hover {
    transform: scale(1.03) !important;
  }

  /* Specific styles for Mobile Devices */
  @media screen and (max-width: 760px) {
    .container {
      display: grid;
      grid-template-areas: 'inner-div';
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: flex;
      text-align: left;
      grid-area: 'inner-div';
      padding: 12px;
    }

    .mobile-filters {
      width: 100%;
    }

    .mobile-filters .el-select {
      display: block;
    }

    .title {
      margin: 0;
      margin-bottom: 20px;
    }

    .label {
      font-weight: bolder;
      text-align: left;
      font-size: 0.8em;
      padding-bottom: 5px;
    }

    .mode-selector {
      width: 100%;
    }

    .style-selector {
      width: 100%;
      margin-top: 20px;
    }
  }
</style>
