<template>
  <div class="norte-desktop-filters">
    <div class="mode-selector" style="margin: 15% 0%">
      <div class="s1-filter-label">Mode</div>
      <el-select v-model="modeSelected" @change="handleModeChange">
        <el-option :label="modeAllOptionName()" value="" />
        <el-option
          v-for="(value, key) in MODES"
          :key="key"
          :label="modeOptionName(value.name, key)"
          :value="key"
        />
      </el-select>
    </div>
    <div class="style-selector" v-if="modeSelected" style="margin: 15% 0%">
      <div class="s1-filter-label">Style</div>
      <el-select v-model="styleSelected" @change="handleStyleChange">
        <el-option :label="styleAllOptionName(modeSelected)" value="" />
        <el-option
          v-for="(value, key) in MODES[modeSelected].styles"
          :key="key"
          :label="styleOptionName(value.name, modeSelected, key)"
          :value="key"
        />
      </el-select>
    </div>
  </div>
</template>
<script>
  import { onBeforeMount, computed, defineComponent } from 'vue'
  import { useStore } from 'vuex'
  import { MODES } from '@/constants/toolPanelConstants'
  import { useRoute } from 'vue-router'
  import {
    getProjectTokensTotal,
    getProjectTokensTotalByMode,
  } from '@/services/TokenService'

  export default defineComponent({
    emits: ['filterTokens'],
    setup(props, context) {
      console.log('props', props)
      const route = useRoute()
      const store = useStore()
      const projectSlug = route.params.project_slug
      const {
        dispatch,
        state: { gallery },
      } = store
      const tokenAttrs = computed(() => gallery.tokenAttrs)
      const modeSelected = computed(
        () => tokenAttrs.value?.find((attr) => attr.name == 'mode')?.value || ''
      )
      const styleSelected = computed(
        () =>
          tokenAttrs.value?.find((attr) => attr.name == 'style')?.value || ''
      )
      const allCounts = computed(() => gallery.allCounts)
      const modeCounts = computed(() => gallery.tokenAttrsCounts)

      const modeAllOptionName = () => {
        return `All (${allCounts.value})`
      }

      const styleAllOptionName = (modeValue) => {
        if (modeCounts.value[modeValue]?.counts) {
          return `All (${modeCounts.value[modeValue]?.counts})`
        } else {
          return 'All'
        }
      }

      const modeOptionName = (name, modeValue) => {
        if (modeCounts.value[modeValue]?.counts) {
          return name + ` (${modeCounts.value[modeValue]?.counts})`
        } else {
          return name
        }
      }

      const styleOptionName = (name, modeValue, styleValue) => {
        if (modeCounts.value[modeValue]?.styles[styleValue].counts) {
          return (
            name +
            ` (${modeCounts.value[modeValue]?.styles[styleValue].counts})`
          )
        } else {
          return name
        }
      }
      const handleModeChange = (modeValue) => {
        let modeAttr = []
        if (modeValue) {
          modeAttr = [{ name: 'mode', value: modeValue, queryIndex: 1 }]
        }
        dispatch('setTokenAttrs', modeAttr)

        context.emit('filterTokens')
      }

      const handleStyleChange = (styleValue) => {
        dispatch('removeTokenAttr', 'style')
        if (styleValue) {
          dispatch('pushTokenAttrs', {
            name: 'style',
            value: styleValue,
            queryIndex: 2,
          })
        }
        context.emit('filterTokens')
      }

      const totalTokens = async () => {
        try {
          let totalCounts = await getProjectTokensTotal(projectSlug)
          if (totalCounts.response > 0) {
            dispatch('setAllCounts', totalCounts.response)
          } else {
            this.$parent.projectNotFound.value = true
            this.$parent.isCollapse.value = true
          }
        } catch (e) {
          console.error(e)
        }
      }

      const loadFilters = async () => {
        try {
          let modeCounts = await getProjectTokensTotalByMode(projectSlug)
          dispatch('setTokenAttrsCounts', modeCounts.response)
        } catch (e) {
          console.error(e)
        }
      }

      const setStateFromQuery = (queryObject) => {
        if (queryObject.mode) {
          dispatch('setTokenAttrs', [
            { name: 'mode', value: queryObject.mode, queryIndex: 1 },
          ])
          if (queryObject.style) {
            dispatch('pushTokenAttrs', {
              name: 'style',
              value: queryObject.style,
              queryIndex: 2,
            })
          }
          context.emit('filterTokens')
        }
      }

      onBeforeMount(async () => {
        setStateFromQuery(route.query)
        await totalTokens()
        await loadFilters()
      })
      return {
        MODES,
        modeSelected,
        styleSelected,
        handleModeChange,
        handleStyleChange,
        modeOptionName,
        styleOptionName,
        modeAllOptionName,
        styleAllOptionName,
      }
    },
  })
</script>
<style scoped>
  .s1-filter-label {
    font-weight: bolder;
    text-align: left;
    font-size: 0.8em;
    margin: 5%;
  }
</style>
