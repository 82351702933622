<template>
  <el-collapse>
    <el-collapse-item title="Filter">
      <div class="attribute-selector">
        <div class="s1-filter-label">Density</div>
        <el-select
          v-model="filterByAttr['density']"
          @change="handleAttributeToFilter('density')"
          :disabled="disableFilters"
        >
          <el-option
            :label="`All (${
              attrsCounts['density']?.counts
                ? attrsCounts['density']?.counts
                : '-'
            })`"
            value=""
          />
          <el-option
            v-for="(value, key) in attrsCounts['density']?.value"
            :key="key"
            :label="attributeOptionName(key, 'density')"
            :value="key"
          />
        </el-select>
      </div>
      <hr class="filter-separator" />
      <div class="attribute-selector">
        <div class="s1-filter-label">Palette</div>
        <el-select
          v-model="filterByAttr['palette']"
          @change="handleAttributeToFilter('palette')"
          :disabled="disableFilters"
        >
          <el-option
            :label="`All (${
              attrsCounts['palette']?.counts
                ? attrsCounts['palette']?.counts
                : '-'
            })`"
            value=""
          />
          <el-option
            v-for="(value, key) in attrsCounts['palette']?.value"
            :key="key"
            :label="attributeOptionName(key, 'palette')"
            :value="key"
          />
        </el-select>
      </div>
      <hr class="filter-separator" />
    </el-collapse-item>
  </el-collapse>
</template>
<script>
  import { computed, defineComponent, ref, onBeforeMount } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'

  import {
    getProjectTokensTotal,
    getEscalerasProjectTokensTotalByAttributes,
  } from '@/services/TokenService'

  export default defineComponent({
    emits: ['filterTokens'],
    props: [],
    setup(props, context) {
      console.log('props', props)
      const route = useRoute()
      const store = useStore()
      const projectSlug = route.params.project_slug
      const {
        dispatch,
        state: { gallery },
      } = store

      const disableFilters = computed(
        () => store.state.gallery.tokensData.length <= 0
      )

      const tokenAttrs = computed(() => gallery.tokenAttrs)
      let quequeToFilter = []
      const densitySelected = computed(
        () =>
          tokenAttrs.value?.find((attr) => attr.name == 'density')?.value || ''
      )
      const paletteSelected = computed(
        () =>
          tokenAttrs.value?.find((attr) => attr.name == 'palette')?.value || ''
      )

      const filterByAttr = ref({
        density: densitySelected.value,
        palette: paletteSelected.value,
      })

      let attrsCounts = computed(() => gallery.tokenAttrsCounts)

      const attributeOptionName = (attrValue, type) => {
        if (attrsCounts.value[type]?.value[attrValue]?.counts) {
          return (
            attrValue +
            ` (${attrsCounts.value[type]?.value[attrValue]?.counts})`
          )
        } else {
          return attrValue
        }
      }

      const totalTokens = async () => {
        try {
          let totalCounts = await getProjectTokensTotal(projectSlug)
          if (totalCounts.response > 0) {
            dispatch('setAllCounts', totalCounts.response)
          } else {
            this.$parent.projectNotFound.value = true
          }
        } catch (e) {
          console.error(e)
        }
      }

      const loadFilters = async () => {
        try {
          let { response } = await getEscalerasProjectTokensTotalByAttributes(
            projectSlug
          )
          dispatch('setTokenAttrsCounts', response)
        } catch (e) {
          console.error(e)
        }
      }

      const handleAttributeToFilter = async (property) => {
        if (!quequeToFilter.length) {
          const queryObject = route.query
          let queryList = []
          for (var query in queryObject) {
            queryList.push([query, queryObject[query]])
          }
          for (const query of queryList) {
            const newFilter = {
              name: query[0],
              value: query[1],
            }
            quequeToFilter.push(newFilter)
          }
        }

        const newFilter = {
          name: property,
          value: filterByAttr.value[property],
        }
        if (quequeToFilter.some((filter) => filter.name === property)) {
          quequeToFilter = quequeToFilter.map((filter) =>
            property === filter.name ? newFilter : filter
          )
        } else {
          quequeToFilter.push(newFilter)
        }

        quequeToFilter = quequeToFilter.filter(
          (option) => option.value != '' && option.name != 'sorting'
        )
        dispatch('setTokenAttrs', [])
        for (let index = 0; index < quequeToFilter.length; index++) {
          quequeToFilter[index].queryIndex = index + 1
          if (index <= 0) {
            const roundAttr = [quequeToFilter[0]]
            dispatch('setTokenAttrs', roundAttr)
          } else {
            //  dispatch('removeTokenAttr', property)
            dispatch('pushTokenAttrs', quequeToFilter[index])
          }
        }
        context.emit('filterTokens')
      }

      const setStateFromQuery = (queryObject) => {
        let result = []
        for (var query in queryObject) {
          if (query !== 'sorting') {
            result.push([query, queryObject[query]])
          }
        }

        for (let index = 0; index < result.length; index++) {
          const newFilter = {
            name: result[index][0],
            value: result[index][1],
            queryIndex: index + 1,
          }
          if (index <= 0) {
            dispatch('setTokenAttrs', [newFilter])
          } else {
            dispatch('pushTokenAttrs', newFilter)
          }
        }
        context.emit('filterTokens')
      }

      onBeforeMount(async () => {
        setStateFromQuery(route.query)

        await totalTokens()
        await loadFilters()
      })

      return {
        filterByAttr,
        disableFilters,
        attrsCounts,
        attributeOptionName,
        handleAttributeToFilter,
      }
    },
  })
</script>

<style scoped>
  .s1-filter-label {
    font-weight: bolder;
    text-align: left;
  }
  .filter-separator {
    border-top: 1px solid #fffbfb;
  }

  /* Specific styles for Mobile Devices */
  @media screen and (max-width: 575px) {
    .attribute-selector {
      width: 100%;
      margin-bottom: 2%;
    }
  }
</style>
