<template>
  <el-collapse>
    <el-collapse-item title="Filter">
      <div class="attribute-selector">
        <div class="s1-filter-label">Word</div>
        <el-select
          v-model="filterByAttr['word']"
          @change="handleAttributeToFilter('word')"
          :disabled="disableFilters"
        >
          <el-option :label="`All (${attrsCounts['word']?.counts})`" value="" />
          <el-option
            v-for="(value, key) in attrsCounts['word']?.value"
            :key="key"
            :label="attributeOptionName(key, 'word')"
            :value="key"
          />
        </el-select>
      </div>
      <hr class="filter-separator" />
      <div class="attribute-selector">
        <div class="s1-filter-label">Round</div>
        <el-select
          v-model="filterByAttr['round']"
          @change="handleAttributeToFilter('round')"
          :disabled="disableFilters"
        >
          <el-option
            :label="`All (${attrsCounts['round']?.counts})`"
            value=""
          />
          <el-option
            v-for="(value, key) in attrsCounts['round']?.value"
            :key="key"
            :label="attributeOptionName(key, 'round')"
            :value="key"
          />
        </el-select>
      </div>
      <hr class="filter-separator" />
      <div class="attribute-selector">
        <div class="s1-filter-label">Type</div>
        <el-select
          v-model="filterByAttr['type']"
          @change="handleAttributeToFilter('type')"
          :disabled="disableFilters"
        >
          <el-option :label="`All (${attrsCounts['type']?.counts})`" value="" />
          <el-option
            v-for="(value, key) in attrsCounts['type']?.value"
            :key="key"
            :label="attributeOptionName(key, 'type')"
            :value="key"
          />
        </el-select>
      </div>
      <hr class="filter-separator" />
      <div class="attribute-selector">
        <div class="s1-filter-label">Font</div>
        <el-select
          v-model="filterByAttr['font']"
          @change="handleAttributeToFilter('font')"
          :disabled="disableFilters"
        >
          <el-option :label="`All (${attrsCounts['font']?.counts})`" value="" />
          <el-option
            v-for="(value, key) in attrsCounts['font']?.value"
            :key="key"
            :label="attributeOptionName(key, 'font')"
            :value="key"
          />
        </el-select>
      </div>
      <hr class="filter-separator" />
    </el-collapse-item>
  </el-collapse>
</template>
<script>
  import { computed, defineComponent, ref, onBeforeMount } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'

  import {
    getProjectTokensTotal,
    getProjectTokensTotalByAttributes,
  } from '@/services/TokenService'

  export default defineComponent({
    emits: ['filterTokens'],
    props: [],
    setup(props, context) {
      console.log('props', props)
      const route = useRoute()
      const store = useStore()
      const projectSlug = route.params.project_slug
      const {
        dispatch,
        state: { gallery },
      } = store

      const tokenAttrs = computed(() => gallery.tokenAttrs)
      const disableFilters = computed(
        () => store.state.gallery.tokensData.length <= 0
      )
      let quequeToFilter = []
      const roundSelected = computed(
        () =>
          tokenAttrs.value?.find((attr) => attr.name == 'round')?.value || ''
      )
      const wordSelected = computed(
        () => tokenAttrs.value?.find((attr) => attr.name == 'word')?.value || ''
      )
      const fontSelected = computed(
        () => tokenAttrs.value?.find((attr) => attr.name == 'font')?.value || ''
      )
      const typeSelected = computed(
        () => tokenAttrs.value?.find((attr) => attr.name == 'type')?.value || ''
      )
      const styleSelected = computed(
        () =>
          tokenAttrs.value?.find((attr) => attr?.name == 'style')?.value || ''
      )

      const filterByAttr = ref({
        round: roundSelected.value,
        word: wordSelected.value,
        type: typeSelected.value,
        font: fontSelected.value,
        style: styleSelected.value,
      })

      let attrsCounts = computed(() => gallery.tokenAttrsCounts)

      const attributeOptionName = (attrValue, type) => {
        if (attrsCounts.value[type]?.value[attrValue]?.counts) {
          return (
            attrValue +
            ` (${attrsCounts.value[type]?.value[attrValue]?.counts})`
          )
        } else {
          return attrValue
        }
      }

      const totalTokens = async () => {
        try {
          let totalCounts = await getProjectTokensTotal(projectSlug)
          if (totalCounts.response > 0) {
            dispatch('setAllCounts', totalCounts.response)
          } else {
            this.$parent.projectNotFound.value = true
          }
        } catch (e) {
          console.error(e)
        }
      }

      const loadFilters = async () => {
        try {
          let { response } = await getProjectTokensTotalByAttributes(
            projectSlug
          )
          dispatch('setTokenAttrsCounts', response)
        } catch (e) {
          console.error(e)
        }
      }

      const handleAttributeToFilter = async (property) => {
        if (!quequeToFilter.length) {
          const queryObject = route.query
          let queryList = []
          for (var query in queryObject) {
            queryList.push([query, queryObject[query]])
          }
          for (const query of queryList) {
            const newFilter = {
              name: query[0],
              value: query[1],
            }
            quequeToFilter.push(newFilter)
          }
        }

        const newFilter = {
          name: property,
          value: filterByAttr.value[property],
        }
        if (quequeToFilter.some((filter) => filter.name === property)) {
          quequeToFilter = quequeToFilter.map((filter) =>
            property === filter.name ? newFilter : filter
          )
        } else {
          quequeToFilter.push(newFilter)
        }

        quequeToFilter = quequeToFilter.filter(
          (option) => option.value != '' && option.name != 'sorting'
        )
        dispatch('setTokenAttrs', [])
        for (let index = 0; index < quequeToFilter.length; index++) {
          quequeToFilter[index].queryIndex = index + 1
          if (index <= 0) {
            const roundAttr = [quequeToFilter[0]]
            dispatch('setTokenAttrs', roundAttr)
          } else {
            //  dispatch('removeTokenAttr', property)
            dispatch('pushTokenAttrs', quequeToFilter[index])
          }
        }
        context.emit('filterTokens')
      }

      const setStateFromQuery = (queryObject) => {
        let result = []
        for (var query in queryObject) {
          if (query !== 'sorting') {
            result.push([query, queryObject[query]])
          }
        }

        for (let index = 0; index < result.length; index++) {
          const newFilter = {
            name: result[index][0],
            value: result[index][1],
            queryIndex: index + 1,
          }
          if (index <= 0) {
            dispatch('setTokenAttrs', [newFilter])
          } else {
            dispatch('pushTokenAttrs', newFilter)
          }
        }
        context.emit('filterTokens')
      }

      onBeforeMount(async () => {
        setStateFromQuery(route.query)

        await totalTokens()
        await loadFilters()
      })

      return {
        filterByAttr,
        disableFilters,
        attrsCounts,
        attributeOptionName,
        handleAttributeToFilter,
      }
    },
  })
</script>

<style scoped>
  .s1-filter-label {
    font-weight: bolder;
    text-align: left;
  }
  .filter-separator {
    border-top: 1px solid #fffbfb;
  }

  /* Specific styles for Mobile Devices */
  @media screen and (max-width: 575px) {
    .attribute-selector {
      width: 100%;
      margin-bottom: 2%;
    }
  }
</style>
